import React from 'react'
import './LongBlogHeader.scss'
import { Grid } from "@mui/material";
import hero_image from '../../../assets/Hero image.png';
import blog_image from '../../../assets/Long Blog 3.png';
import DownloadbtnText from '../../../staticcomponents/downloadbtn-text'
import Container from '@material-ui/core/Container';

export default function LongBlogHeader() {
  return (
    <>
      <Container maxWidth="xl" className='long-blog-container-cls'>
        <Grid className="long-blog-header-cls">
          {/* **********************    Text-with-Image-row    ********************** */}
          <Grid container className="Text-with-hero-img">
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <div className="header-text">
                <div className="header-text-div">
                  <p className="header-date">
                    BLOGS / 22 JUN 2022
                  </p>
                  <span className="header-title">
                    rising inflation and the importance of cost of living allowance
                  </span>
                  <p className="author-name">
                    Daisy Jennett
                  </p>
                </div>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className="header-heroimg">
              <img src={hero_image} alt="hero image" />
            </Grid>

          </Grid>
          {/* **********************   Text-row-2    ********************** */}
          <Grid container className="rich-text-para-padding">
            <Grid item xl={6} lg={6} md={2} sm={2} xs={0}></Grid>
            <Grid item xl={6} lg={6} md={10} sm={10} xs={12} className="rich-text-para1">
              <p>
                Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well, many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over 200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily life.
              </p>
            </Grid>
          </Grid>
          {/* <Grid item xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
        <Grid container item xl={11} lg={11} md={12} sm={12} xs={12}>
          <Grid item xl={5} lg={5} md={2} sm={2} xs={0}></Grid>
          <Grid item xl={6} lg={6} md={10} sm={10} xs={12} className="rich-text-para">        
              <p>
                Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well, many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over 200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily life.
              </p>
          </Grid>
        </Grid> */}

          {/* **********************   Text-row-3   ********************** */}


          <Grid container className="rich-text-para-padding">
            <Grid item xl={0} lg={0} md={2} sm={2} xs={0}></Grid>
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={10}
              sm={10}
              xs={12}
              className="section-with-blue-div"
            >
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className="text-with-download-div">
                <DownloadbtnText />
              </Grid>
              <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="rich-text-para"
                  locId="richTextContent2"
                >
                  <div className="subtitle">what can mobility managers do?</div>
                  <p>
                    While there is no one-size-fits-all answer for multinational organizations with employees on assignment, mobility managers should be prepared to examine existing practices and evaluate compensation structures and assignment allowances carefully on a case-by-case basis.
                  </p>
                  <p>
                    Many companies leverage the popular balance sheet approach, which is rooted in the theory that administering this method ensures an assignee is no better or worse off than if they remained at the home location. However, there’s also a mechanism to overcome these challenges and their risk to employees working abroad—the cost of living allowance!
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* **********************   Text-row-4  ********************** */}

          <Grid container className="blog-image-div-padding">
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="top-padding">
               <Grid item xl={6} lg={6} md={11} sm={11} xs={12} className="blog-image-div">
              <img src={blog_image} alt="blog image" />
              </Grid>

                <Grid container xl={6} lg={6} md={12} sm={12} xs={12}>
                  <Grid item xl={0} lg={0} md={2} sm={2} xs={0}></Grid>
                  <Grid item xl={12} lg={12} md={10} sm={10} xs={12} className="rich-text-para">
                  <div className="subtitle">the importance of cost of living allowance</div>
                <p>
                  A cost of living allowance (COLA) is the policy benefit payable to assignees while on an assignment that is above and beyond their established salary. It is designed to cover additional day-to-day living expenses in the host country, which may vary from city to city and whether the location is a large urban center or not.
                </p>
                <p>
                  It’s crucial to understand the role a cost of living allowance plays in diffusing the effects of rising inflation. COLA counteracts the effects of currency fluctuation and inflation, but to be truly effective, allowances must be reviewed regularly to ensure budgets are in line with the most current currency and inflationary rates.
                </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          {/* **********************   Text-row-5    ********************** */}

          <Grid container className="blog-image-div-padding">
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={6} lg={6} md={11} sm={11} xs={12} className="blog-image-div">
              <img src={blog_image} alt="blog image" />
              </Grid>
                

                <Grid container xl={6} lg={6} md={12} sm={12} xs={12}>
                  <Grid item xl={0} lg={0} md={2} sm={2} xs={0}></Grid>
                  <Grid item xl={12} lg={12} md={10} sm={10} xs={12} className="rich-text-para">
                  <div className="subtitle">following are key considerations when establishing cola start of an assignment: </div>
              <p>
                <ul>
                  <li>When calculating budgets, does your adopted method work for the current real estate market and supply in the host location?</li>
                  <li>Is your budget based on an assignee’s job level or the size of the relocating family?</li>
                  <li>What index do you use to assess COLA differences between the home and host locations? For example, do you use inexpensive or moderate ranges to establish a budget?</li>
                  <li>What is your current practice and cadence for reviewing and updating your COLA?</li>
                  <li>If you’re not on a split payroll, what thresholds have you set for currency fluctuations?</li>
                </ul>
              </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
        </Grid>
      </Container>
    </>
  )
}


