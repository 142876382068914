import React from 'react'
import Grid from '@material-ui/core/Grid';
import './LongBlogOurCompany.scss'
import Container from '@material-ui/core/Container';

export default function LongBlogOurCompany() {
    return (
        <>
            {/* How Company Starts */}
   
<Container maxWidth="xl" className="long-blog-container-comp-cls">
      <Grid className="comp-admin">
          <Grid className="mob-col-space">
            <Grid container>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Grid className="comp-para-one">
                <h3>how does your company administer payroll?</h3>
                <p>Some organizations opt to pay 100% of an assignee’s payroll from the home location, but for companies who deliver a split payroll, an assignee’s salary is divided into two parts: </p>
                <p><b>Non-spendable income: </b>The portion of the compensation package typically paid in the home location </p>
                <p><b>Spendable income:</b> The portion of the compensation package designed to cover daily living expenses in the host location, e.g., goods and services. To protect against high inflation and currency fluctuation, the index should only be applied to this portion of the compensation package.</p>
                </Grid>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Grid className="comp-para-two">
                <p>Encouragingly, there are initial signs that inflation around the world is likely to die down over the coming year. However, quality of life and well-being are increasingly vital to today's employees, especially in a post-pandemic world. As a result, priorities have evolved, and, to attract and retain talent, companies must ensure that their mobility programs offer the flexibility and solutions to deliver in this ever-changing environment. Companies that do not provide COLA adjustments therefore might be at risk of losing key talent to competitors who do offer such a benefit.</p>
                <p>In addition, assignees in higher COLA locations will return home to have more disposable income. With this in mind, now is an excellent time to examine how your company administers this policy provision. It is also crucial that mobility managers have an in-depth understanding of compensation assumptions to communicate changes to employees clearly and proactively. </p>
                <p>To find out more about this topic or any other aspect of your global mobility program, please contact your Cartus representative or email <a href="mailto:?subject=">cartussolutions@cartus.com</a>.</p>
                </Grid>
        </Grid>
            </Grid>
          </Grid>
      </Grid>
    </Container>
      </>
    )
}


