import React from 'react'
import Layout from '../../staticcomponents/layout'
import LongBlogHeader from './longblogheader'
import LongBlogOurCompany from './longblogourcompany'
import './longblog.scss'
import CategorySocialMedia from '../../staticcomponents/category-social media'
import AuthorDetails from '../../staticcomponents/author details'



export default function Longblog() {
    return (
        <>
            <Layout>
                <div className='LongBlog_main'>
                    <div className='bg-donuts-right'>
                        <LongBlogHeader />
                    </div>
                    <LongBlogOurCompany />
                    <CategorySocialMedia />
                    <AuthorDetails />

                </div>
            </Layout>
        </>
    )
}